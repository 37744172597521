<template>
  <div>
    <NavigationBar
      showBackBtn
      titel="Details"
      :actions="actions"
    ></NavigationBar>
    <error-message
      v-if="error.message"
      :message="error.message"
    ></error-message>
    <section>
      <template>
        <v-container>
          <v-row v-if="helpers.loading">
            <v-col cols="12">
              <v-skeleton-loader type="sentences"></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col cols="12" sm="12" md="6">
              <Subheader icon="mdi-card-text" title="Allgemein"> </Subheader>
              <div class="mt-7">
                <v-row>
                  <v-col cols="12">
                    <leave-details-card :leave="leave"></leave-details-card>
                  </v-col>
                </v-row>
              </div>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <Subheader icon="mdi-autorenew" title="Automation"> </Subheader>
              <div class="mt-7">
                <v-row>
                  <v-col cols="12">
                    <v-card v-if="leave.automation.eventResponses.areAutomated">
                      <v-card-subtitle
                        >Es werden automatisch für alle Veranstaltungen im
                        angegeben Zeitraum Rückmeldungen mit den folgenden Daten
                        angelegt:</v-card-subtitle
                      >
                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon>mdi-calendar-question</v-icon>
                        </v-list-item-icon>
                        <v-list-item-subtitle>
                          <v-chip
                            color="red lighten-4"
                            class="font-weight-medium red--text text--darken-4"
                            small
                            ><v-icon small left>mdi-close-circle</v-icon>Nicht
                            verfügbar</v-chip
                          ></v-list-item-subtitle
                        >
                      </v-list-item>
                      <v-list-item
                        v-if="leave.automation.eventResponses.comment"
                      >
                        <v-list-item-icon>
                          <v-icon>mdi-comment-text</v-icon>
                        </v-list-item-icon>
                        <v-list-item-subtitle
                          >{{ leave.automation.eventResponses.comment }}
                        </v-list-item-subtitle>
                      </v-list-item>
                    </v-card>
                    <v-card v-else>
                      <v-card-subtitle
                        >Es werden keine automatischen Rückmeldungen für
                        Veranstaltungen angelegt.</v-card-subtitle
                      >
                    </v-card>
                  </v-col>
                </v-row>
              </div>
            </v-col>
            <v-col cols="12">
              <Subheader icon="mdi-history" title="Logs"> </Subheader>
              <div class="">
                <v-timeline dense align-top>
                  <v-timeline-item
                    v-if="permissionToManageLeaves"
                    fill-dot
                    class="mb-2"
                    color="white"
                    icon-color="grey"
                    icon="mdi-pencil"
                    small
                  >
                    <v-card>
                      <v-card-text>
                        <v-textarea
                          v-model="helpers.commentForm.value"
                          dense
                          label="Kommentar hinzufügen"
                          hide-details="auto"
                          outlined
                          no-resize
                          auto-grow
                          rows="3"
                        >
                        </v-textarea>
                        <v-alert
                          v-if="helpers.commentForm.errorMessage"
                          dense
                          type="error"
                          text
                          class="mt-4 mb-0"
                        >
                          {{ helpers.commentForm.errorMessage }}</v-alert
                        >
                      </v-card-text>
                      <v-divider></v-divider>
                      <v-card-actions>
                        <!-- <v-checkbox
                          dense
                          disabled
                          label="Benachrichtigung per E-Mail senden"
                          persistent-hint
                          hint="Eine Benachrichtigung über diesen Eintrag wird per E-Mail an ausgewählte Personen gesendet."
                        ></v-checkbox> -->
                        <notifications-forecast-preview-card
                          infoText="Es wird versucht, berechtigte Personen zu benachrichtigen."
                          :notifyPersonsUids="
                            availabilityConfig.leaves.notifyPersons
                          "
                          notificationTriggerId="availability_leaveCommentActivity"
                          hidden
                        ></notifications-forecast-preview-card>
                        <v-spacer></v-spacer
                        ><v-btn
                          class="mx-0"
                          color="success"
                          depressed
                          :loading="helpers.commentForm.isLoading"
                          :disabled="!helpers.commentForm.value"
                          @click="addComment"
                        >
                          Hinzufügen
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-timeline-item>

                  <v-slide-x-transition group>
                    <div
                      v-for="entry in logsAndComments"
                      :key="entry.id"
                      class="mb-2"
                    >
                      <v-timeline-item
                        color="white"
                        fill-dot
                        small
                        :icon-color="logEntryActionColor(entry.type)"
                        :icon="logEntryActionIcon(entry.type)"
                      >
                        <v-card flat class="elevation-2">
                          <v-card-text>
                            <v-row justify="space-between">
                              <v-col cols="7">
                                <strong>{{ entry.user.displayName }}</strong>
                                {{ logEntryActionText(entry.type) }}
                                <span v-if="entry.type === 'comment'">
                                  <br />{{ entry.text }}
                                </span>
                                <span v-if="entry.reason">
                                  <br />Grund: {{ entry.reason }}
                                </span>
                              </v-col>
                              <v-col class="text-right" cols="5">{{
                                timestampConverter(entry.timestamp)
                              }}</v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-timeline-item>
                    </div>
                  </v-slide-x-transition>
                  <v-divider></v-divider>
                </v-timeline>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <section v-if="checkIfSupport">
      <support-tools :sources="code"></support-tools>
    </section>
  </div>
</template>

<script>
import { db, auth, FieldValue } from "@/firebase";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import NotificationsForecastPreviewCard from "@/components/_systemwide/NotificationsForecastPreviewCard.vue";
import Subheader from "@/components/_systemwide/Subheader.vue";
import SupportTools from "@/components/_system/helpers/SupportTools.vue";
import LeaveDetailsCard from "@/components/availability/leaves/LeaveDetailsCard.vue";
import ErrorMessage from "@/components/_systemwide/ErrorMessage.vue";
import {
  AVAILABILITY_LEAVES_update,
  AVAILABILITY_LEAVES_delete,
  AVAILABILITY_LEAVES_manage_all,
} from "@/data/permission-types.js";

export default {
  name: "availability-leaves-details",
  components: {
    NavigationBar,
    Subheader,
    SupportTools,
    ErrorMessage,
    LeaveDetailsCard,
    NotificationsForecastPreviewCard,
  },
  data() {
    return {
      actions: [
        {
          title: "Bearbeiten",
          permissions: `${AVAILABILITY_LEAVES_update}`,
          icon: "mdi-pencil",
          actionStyle: "textBtn",
          function: () => {
            this.$router.push({
              name: "availability-leaves-edit",
              params: {
                organizationId: this.$route.params.organizationId,
                itemId: this.$route.params.itemId,
              },
            });
          },
        },
        {
          title: "Aktionen",
          icon: "mdi-dots-horizontal-circle",
          actionStyle: "textBtnMultiple",
          list: [
            {
              title: "Vorzeitig beenden",
              permissions: `${AVAILABILITY_LEAVES_update}`,
              icon: "mdi-check-circle",
              actionStyle: "textBtnSubmit",
              disabled: true,
              function: this.endLeave,
            },
            {
              title: "Löschen",
              permissions: `${AVAILABILITY_LEAVES_delete}`,
              icon: "mdi-delete",
              actionStyle: "textBtn",
              disabled: true,
              function: this.deleteItem,
            },
          ],
        },
        { divider: true },
        { actionStyle: "clipboardBtn" },
      ],

      helpers: {
        loading: true,
        commentForm: {
          value: "",
          errorMessage: "",
          isLoading: false,
        },
      },
      error: {
        message: "",
      },
      logs: [],
      comments: [],
      leave: null,
    };
  },
  computed: {
    availabilityConfig() {
      return this.$store.state.organization.activeOrganization.config
        .availability;
    },
    checkIfSupport() {
      return this.$store.getters["organization/checkIfSupport"];
    },
    code() {
      return [
        { title: "leave", data: this.leave },
        { title: "logsAndComments", data: this.logsAndComments },
        { title: "comments", data: this.comments },
        { title: "logs", data: this.logs },
      ];
    },
    permissionToManageLeaves() {
      return this.$store.getters["organization/checkPermissionByID"](
        `${AVAILABILITY_LEAVES_manage_all}`
      );
    },
    logsAndComments() {
      return this.logs
        .concat(this.comments)
        .sort((a, b) => b.timestamp - a.timestamp);
    },
  },
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    async initialize() {
      await this.getLeave();
      await this.getLogs();
      await this.getComments();
      this.helpers.loading = false;
    },
    getLeave() {
      return db
        .collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("leaves")
        .doc(this.$route.params.itemId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.leave = {
              ...doc.data(),
              status: this.getLeaveStatus(
                doc.data().duration.start.timestamp.toDate(),
                doc.data().duration.end.timestamp.toDate()
              ),
            };
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
            this.error.message = "Fehler: Abwesenheitsmeldung nicht vorhanden.";
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
          this.error.message =
            "Fehler: Abwesenheitsmeldung kann nicht geladen werden.";
          return false;
        });
    },
    getLogs() {
      return db
        .collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("leaves")
        .doc(this.$route.params.itemId)
        .collection("logs")
        .get()
        .then((querySnapshot) => {
          const logs = [];
          querySnapshot.forEach((doc) => {
            logs.push(doc.data());
          });
          this.logs = logs;
        })
        .catch((error) => {
          console.log("Error getting document:", error);
          return false;
        });
    },
    getComments() {
      return db
        .collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("leaves")
        .doc(this.$route.params.itemId)
        .collection("comments")
        .get()
        .then((querySnapshot) => {
          const comments = [];
          querySnapshot.forEach((doc) => {
            comments.push(doc.data());
          });
          this.comments = comments;
        })
        .catch((error) => {
          console.log("Error getting document:", error);
          return false;
        });
    },
    timestampConverter(timestamp) {
      const options = {
        weekday: "long",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };
      return timestamp.toDate().toLocaleDateString("de-DE", options);
    },
    addComment() {
      if (this.helpers.commentForm.value != "") {
        this.helpers.commentForm.isLoading = true;
        const commentRef = db
          .collection("organizations")
          .doc(this.$route.params.organizationId)
          .collection("leaves")
          .doc(this.$route.params.itemId)
          .collection("comments")
          .doc();

        const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

        return commentRef
          .set({
            user: {
              displayName: auth.currentUser.displayName,
              uid: auth.currentUser.uid,
            },
            type: "comment",
            text: this.helpers.commentForm.value,
            mentions: [],
            timestamp: FieldValue.serverTimestamp(),
            id: commentRef.id,
          })
          .then(async () => {
            this.getComments();
            await delay(800);
            this.helpers.commentForm.value = "";
            this.helpers.commentForm.isLoading = false;
          })
          .catch(async (error) => {
            console.error("Error adding document: ", error);
            await delay(800);
            this.helpers.commentForm.errorMessage = error.message;
            this.helpers.commentForm.isLoading = false;
          });
      } else {
        this.helpers.commentForm.errorMessage =
          "Fehler: Es sind nicht alle Pflichtangaben ausgefüllt.";
      }
    },
    logEntryActionText(type) {
      switch (type) {
        case "created":
          return "hat die Abwesenheitsmeldung erstellt.";
        case "notificationSent":
          return "hat eine Benachrichtigung gesendet.";
        case "updated":
          return "hat die Abwesenheitsmeldung aktualisiert.";
        case "closed":
          return "hat die Abwesenheitsmeldung vorzeitig abgeschlossen.";
        case "comment":
          return "hat einen Kommentar hinzugefügt:";
        default:
          break;
      }
    },
    logEntryActionColor(type) {
      switch (type) {
        case "created":
          return "primary";
        case "notificationSent":
          return "indigo";
        case "updated":
          return "orange";
        case "closed":
          return "green";
        case "comment":
          return "grey";
        default:
          break;
      }
    },
    logEntryActionIcon(type) {
      switch (type) {
        case "created":
          return "mdi-plus";
        case "notificationSent":
          return "mdi-email";
        case "updated":
          return "mdi-pencil";
        case "closed":
          return "mdi-check";
        case "comment":
          return "mdi-comment-text";

        default:
          break;
      }
    },
    getLeaveStatus(start, end) {
      const now = new Date().getTime();

      const startTime = new Date(start).getTime();
      const endTime = new Date(end).getTime();

      if (isNaN(startTime) || isNaN(endTime)) {
        return "error";
      }

      if (startTime > now) {
        return "upcoming";
      } else if (endTime < now) {
        return "finished";
      } else {
        return "active";
      }
    },
    endLeave() {},
    deleteItem() {},
  },
};
</script>
