<template>
  <div>
    <NavigationBar
      showBackBtn
      titel="Abwesenheitsmeldung bearbeiten"
      :actions="actions"
    ></NavigationBar>
    <error-message
      v-if="error.message"
      :message="error.message"
    ></error-message>
    <section>
      <v-form
        @submit.prevent="updateItem"
        ref="form"
        v-model="helpers.formIsValid"
      >
        <leave-editor v-model="leave" mode="edit"></leave-editor>
      </v-form>
    </section>
  </div>
</template>

<script>
import { db, Timestamp } from "@/firebase";
import { AVAILABILITY } from "@/store/modules.js";
import { UPDATE_LEAVE } from "@/store/action-types.js";
import { AVAILABILITY_LEAVES_update } from "@/data/permission-types.js";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import ErrorMessage from "@/components/_systemwide/ErrorMessage.vue";
import LeaveEditor from "@/components/availability/leaves/LeaveEditor.vue";
export default {
  name: "availability-leaves-edit",
  components: {
    NavigationBar,
    ErrorMessage,
    LeaveEditor,
  },
  data() {
    return {
      actions: [
        {
          title: "Speichern",
          permission: `${AVAILABILITY_LEAVES_update}`,
          icon: "mdi-content-save",
          actionStyle: "textBtnSubmit",
          function: this.updateItem,
        },
      ],

      helpers: {
        formIsValid: false,
      },
      error: {
        message: "",
      },

      leave: null,
    };
  },
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    initialize() {
      db.collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("leaves")
        .doc(this.$route.params.itemId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.leave = doc.data();
            this.leave.duration.start = {
              date: this.convertToDate(doc.data().duration.start.timestamp),
              time: this.convertToTime(doc.data().duration.start.timestamp),
            };
            this.leave.duration.end = {
              date: this.convertToDate(doc.data().duration.end.timestamp),
              time: this.convertToTime(doc.data().duration.end.timestamp),
              isOpen: doc.data().duration.end.isOpen,
            };
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
            this.error.message = "Fehler: Abwesenheitsmeldung nicht vorhanden.";
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          this.error.message =
            "Fehler: Abwesenheitsmeldung kann nicht geladen werden.";
          return false;
        });
    },
    convertToTimestamp(date, time) {
      const input = date + " " + time;
      return Timestamp.fromDate(new Date(input.replace(/-/g, "/")));
    },
    convertToDate(date) {
      date = date.toDate();
      return (
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1 < 10 ? "0" : "") +
        (date.getMonth() + 1) +
        "-" +
        (date.getDate() < 10 ? "0" : "") +
        date.getDate()
      );
    },
    convertToTime(time) {
      time = time.toDate();
      return (
        (time.getHours() < 10 ? "0" : "") +
        time.getHours() +
        ":" +
        (time.getMinutes() < 10 ? "0" : "") +
        time.getMinutes()
      );
    },
    updateItem() {
      this.$refs.form.validate();
      if (this.helpers.formIsValid) {
        this.$store
          .dispatch(`${AVAILABILITY}${UPDATE_LEAVE}`, {
            organizationId: this.$route.params.organizationId,
            itemId: this.$route.params.itemId,

            duration: {
              start: {
                timestamp: this.convertToTimestamp(
                  this.leave.duration.start.date,
                  this.leave.duration.start.time
                ),
              },
              end: {
                timestamp: this.convertToTimestamp(
                  this.leave.duration.end.date,
                  this.leave.duration.end.time
                ),
                isOpen: this.leave.duration.end.isOpen,
              },
            },
            reason: {
              id: this.leave.reason.id,
              details: this.leave.reason.details,
            },
            automation: {
              eventResponses: {
                areAutomated: this.leave.automation.eventResponses.areAutomated,
                status: this.leave.automation.eventResponses.status,
                comment: this.leave.automation.eventResponses.comment,
              },
            },
          })
          .then(() => {
            this.$router.push({
              name: "availability-leaves-details",
              params: {
                organizationId: this.$route.params.organizationId,
                itemId: this.$route.params.itemId,
              },
            });
          })
          .catch((error) => {
            console.error("Error updating document: ", error);
            this.error.message =
              "Fehler: Abwesenheitsmeldung kann nicht gespeichert werden.";
          });
      } else {
        alert("Prüfen Deine Eingabe und versuchen es erneut.");
      }
    },
  },
};
</script>
