<template>
  <div>
    <template>
      <v-container>
        <v-row justify="center" align="center">
          <v-col cols="12">
            <Subheader icon="mdi-card-text" title="Allgemein"></Subheader>
            <div class="mt-7">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row v-if="permissionToManageLeaves">
                      <v-col v-if="mode === 'new'" cols="12" sm="6">
                        <v-autocomplete
                          v-model="leaveLocal.user"
                          :items="memberships"
                          outlined
                          hide-details="auto"
                          return-object
                          label="Person für Abmeldung"
                          :disabled="mode === 'edit'"
                          item-text="displayName"
                          item-value="uid"
                          :rules="[rules.required]"
                        ></v-autocomplete>
                      </v-col>
                      <v-col v-else cols="12" sm="6">
                        <v-text-field
                          v-model="leaveLocal.user.displayName"
                          outlined
                          hide-details="auto"
                          disabled
                          label="Person für Abmeldung"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="auto">
                        <v-btn depressed @click="setNow('start')"
                          ><v-icon left>mdi-clock-in</v-icon>Jetzt
                          einsetzen</v-btn
                        >
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="leaveLocal.duration.start.date"
                          type="date"
                          label="Startdatum"
                          hint="DD.MM.YYYY"
                          outlined
                          :rules="[rules.required, rules.startBeforeEnd]"
                          required
                          prepend-inner-icon="mdi-calendar-start"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="3">
                        <v-text-field
                          v-model="leaveLocal.duration.start.time"
                          label="Startzeit"
                          hint="HH:MM"
                          :rules="[rules.required, rules.startBeforeEnd]"
                          outlined
                          type="time"
                          prepend-inner-icon="mdi-clock-start"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="leaveLocal.duration.end.date"
                          type="date"
                          label="Enddatum"
                          hint="DD.MM.YYYY"
                          outlined
                          :rules="[rules.required, rules.startBeforeEnd]"
                          required
                          prepend-inner-icon="mdi-calendar-end"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="3">
                        <v-text-field
                          v-model="leaveLocal.duration.end.time"
                          label="Endzeit"
                          hint="HH:MM"
                          outlined
                          :rules="[rules.required, rules.startBeforeEnd]"
                          type="time"
                          prepend-inner-icon="mdi-clock-end"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="5">
                        <v-checkbox
                          v-model="leaveLocal.duration.end.isOpen"
                          label="Genauer Endzeitpunkt noch offen"
                          persistent-hint
                          hint="Eine entsprechende Kennzeichnung wird der Abmeldung hinzugefügt."
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-select
                          v-model="leaveLocal.reason.id"
                          :items="reasonItems"
                          item-value="id"
                          item-text="title"
                          outlined
                          persistent-hint
                          hint="Diese Auswahl wird möglicherweise anderen Mitgliedern angezeigt."
                          label="Grund für Abmeldung auswählen"
                          :rules="[rules.required]"
                        >
                          <template v-slot:item="{ item, attrs, on }">
                            <v-list-item v-on="on" v-bind="attrs">
                              <v-list-item-content>
                                <v-list-item-title>
                                  {{ item.title }}
                                </v-list-item-title>
                                <v-list-item-subtitle>{{
                                  item.description
                                }}</v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-select>
                      </v-col>
                      <!-- <v-col cols="12" md="6">
                        <v-select
                          item-value="id"
                          item-text="title"
                          outlined
                          persistent-hint
                          disabled
                          multiple
                          hint="Stehen nur einzelne Qualifikationen nicht zur Verfügung, können die von der Abmeldung betroffenen Qualifikationen hier einzeln gepflegt werden."
                          label="Abgemeldete Qualifikationen"
                          :rules="[rules.required]"
                        >
                        </v-select>
                      </v-col> -->
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-textarea
                          v-model.trim="leaveLocal.reason.details"
                          hide-details="auto"
                          clearable
                          no-resize
                          rows="3"
                          label="Bemerkungen"
                          outlined
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
          <v-col cols="12">
            <Subheader icon="mdi-autorenew" title="Automation"></Subheader>
            <div class="mt-7">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <!-- v-model="
                            leaveLocal.automation.eventResponses.areAutomated
                          " -->
                        <v-checkbox
                          disabled
                          label="Automatisches Abmelden von Veranstaltungen in diesem Zeitraum"
                          persistent-hint
                          hint="Allen Veranstaltungen im angegebenen Zeitraum wird automatisch eine Rückmeldung hinzugefügt. Es ist sichtbar, dass diese Rückmeldung automatisch erstellt wurde."
                        ></v-checkbox>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        v-if="leaveLocal.automation.eventResponses.areAutomated"
                      >
                        <v-select
                          v-model="leaveLocal.automation.eventResponses.status"
                          :items="statusItems"
                          item-text="status"
                          label="Rückmeldung für Automation"
                          persistent-hint
                          hint="Diese Rückmeldung wird allen Veranstaltungen im angegeben Zeitraum hinzugefügt."
                          outlined
                        >
                          <template v-slot:item="data">
                            <v-list-item>
                              <v-list-item-action>
                                <v-icon :color="data.item.color">
                                  {{ data.item.icon }}
                                </v-icon>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title>
                                  {{ data.item.status }}
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-divider class="mt-2"></v-divider>
                          </template>
                          <template v-slot:selection="data">
                            <v-list-item>
                              <v-list-item-action>
                                <v-icon :color="data.item.color">
                                  {{ data.item.icon }}
                                </v-icon>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title>
                                  {{ data.item.status }}
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-divider class="mt-2"></v-divider>
                          </template>
                        </v-select>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        v-if="leaveLocal.automation.eventResponses.areAutomated"
                      >
                        <v-textarea
                          v-model.trim="
                            leaveLocal.automation.eventResponses.comment
                          "
                          counter
                          clearable
                          no-resize
                          persistent-hint
                          rows="3"
                          hint="Dieser Kommentar wird allen Veranstaltungen im angegeben Zeitraum hinzugefügt."
                          label="Kommentar"
                          outlined
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </div>
</template>

<script>
import { Timestamp } from "@/firebase";
import Subheader from "@/components/_systemwide/Subheader.vue";
import { AVAILABILITY_LEAVES_manage_all } from "@/data/permission-types.js";
export default {
  name: "leave-editor",
  props: {
    leave: {},
    mode: {
      type: String,
      default: "new",
      required: true,
      validator: function (value) {
        return ["new", "edit"].indexOf(value) !== -1;
      },
    },
  },
  components: {
    Subheader,
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
        startBeforeEnd: () =>
          (!this.fieldsAreFilled &&
            this.convertToTimestamp(
              this.leaveLocal.duration.start.date,
              this.leaveLocal.duration.start.time
            ).valueOf() <
              this.convertToTimestamp(
                this.leaveLocal.duration.end.date,
                this.leaveLocal.duration.end.time
              ).valueOf()) ||
          "Start muss vor Ende liegen",
      },

      statusItems: [
        // {
        //   icon: "mdi-check",
        //   value: "1",
        //   status: "Verfügbar",
        //   color: "success",
        // },
        {
          icon: "mdi-close",
          value: "2",
          status: "Nicht verfügbar",
          color: "error",
        },
        {
          icon: "mdi-minus",
          value: "3",
          status: "Wenn notwendig",
          color: "warning",
        },
        {
          icon: "mdi-airplane",
          value: "4",
          status: "Nicht in Stadt",
          color: "info",
        },
      ],
      reasonItems: [
        { id: "0", title: "Keine Angabe", description: "" },
        {
          id: "1",
          title: "Verpflichtungen/Verbindlichkeiten/Termine",
          description: "Bspw. berufliche, private, familiäre Verpflichtungen",
        },
        { id: "2", title: "Nicht in Stadt", description: "Nicht erreichbar" },
        {
          id: "3",
          title: "Keine Diensttauglichkeit",
          description: "Bspw. aufgrund von Krankheit, Verletzung, Ablauf G26.3",
        },
        {
          id: "4",
          title: "Eingeschränkte Diensttauglichkeit",
          description: "Bspw. duch Ablauf von Prüfdaten",
        },
        {
          id: "8",
          title: "Beurlaubung",
          description: "Ruhen von Rechten und Pflichten",
        },
        { id: "9", title: "Sonstiges", description: "" },
      ],
    };
  },
  model: {
    prop: "leave",
    event: "leaveChange",
  },
  computed: {
    leaveLocal: {
      get: function () {
        return this.leave;
      },
      set: function (value) {
        this.$emit("leaveChange", value);
      },
    },
    memberships() {
      var result = this.$store.state.admin.membershipsPublic.map((item) => {
        return {
          displayName: item.user.displayName,
          membershipId: item.user.uid,
          uid: item.user.uid,
        };
      });
      return result;
    },
    permissionToManageLeaves() {
      return this.$store.getters["organization/checkPermissionByID"](
        `${AVAILABILITY_LEAVES_manage_all}`
      );
    },
    fieldsAreFilled() {
      if (
        !this.leaveLocal.duration.start.date === "" &&
        !this.leaveLocal.duration.start.time === "" &&
        !this.leaveLocal.duration.end.date === "" &&
        !this.leaveLocal.duration.end.time === ""
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    convertToTimestamp(date, time) {
      const input = date + " " + time;
      return Timestamp.fromDate(new Date(input.replace(/-/g, "/")));
    },
    setNow(type) {
      const now = new Date();
      const date = now.toISOString().split("T")[0];
      const time = now
        .toTimeString()
        .split(" ")[0]
        .split(":")
        .slice(0, 2)
        .join(":");
      if (type === "start") {
        this.$set(this.leaveLocal.duration.start, "date", date);
        this.$set(this.leaveLocal.duration.start, "time", time);
      } else if (type === "end") {
        this.$set(this.leaveLocal.duration.end, "date", date);
        this.$set(this.leaveLocal.duration.end, "time", time);
      }
    },
  },
};
</script>
