<template>
  <div>
    <NavigationBar
      showBackBtn
      titel="Abwesenheit melden"
      :actions="actions"
    ></NavigationBar>
    <error-message
      v-if="error.message"
      :message="error.message"
    ></error-message>
    <section>
      <v-form
        v-model="helpers.formIsValid"
        @submit.prevent="createItem"
        ref="form"
      >
        <leave-editor v-model="leave" mode="new"></leave-editor>
      </v-form>
    </section>
  </div>
</template>

<script>
import { Timestamp, auth } from "@/firebase";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import ErrorMessage from "@/components/_systemwide/ErrorMessage.vue";
import LeaveEditor from "@/components/availability/leaves/LeaveEditor.vue";
import {
  AVAILABILITY_LEAVES_create,
  AVAILABILITY_LEAVES_manage_all,
} from "@/data/permission-types.js";
import { ADMIN, AVAILABILITY } from "@/store/modules.js";
import { GET_USER_LIST, CREATE_LEAVE } from "@/store/action-types.js";

export default {
  name: "availability-leaves-new",
  components: {
    NavigationBar,
    ErrorMessage,
    LeaveEditor,
  },
  data() {
    return {
      actions: [
        {
          title: "Speichern",
          permission: `${AVAILABILITY_LEAVES_create}`,
          icon: "mdi-content-save",
          actionStyle: "textBtnSubmit",
          function: this.createItem,
        },
      ],

      helpers: {
        formIsValid: false,
      },
      error: {
        message: "",
      },

      leave: {
        duration: {
          start: {
            date: "",
            time: "00:00",
          },
          end: {
            date: "",
            time: "00:00",
            isOpen: false,
          },
        },
        reason: {
          id: "",
          details: "",
        },
        automation: {
          eventResponses: {
            areAutomated: false,
            status: null,
            comment: "",
          },
        },
        user: {
          displayName: auth.currentUser.displayName,
          membershipId: auth.currentUser.uid,
          uid: auth.currentUser.uid,
        },
      },
    };
  },
  computed: {},
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    async initialize() {
      const hasPermission = await this.permissionToManageLeaves;
      if (hasPermission) {
        this.$store.dispatch(`${ADMIN}${GET_USER_LIST}`, {
          organizationId: this.$route.params.organizationId,
          queryIsFiltered: true,
          query: {
            type: "active",
          },
        });
      }
    },
    async permissionToManageLeaves() {
      return await this.$store.getters["organization/checkPermissionByID"](
        `${AVAILABILITY_LEAVES_manage_all}`
      );
    },
    convertToTimestamp(date, time) {
      const input = date + " " + time;
      return Timestamp.fromDate(new Date(input.replace(/-/g, "/")));
    },
    validate() {
      this.$refs.form.validate();
    },
    createItem() {
      this.$refs.form.validate();
      if (this.helpers.formIsValid) {
        if (
          this.leave.user.uid !== auth.currentUser.uid &&
          !this.permissionToManageLeaves
        ) {
          alert(
            "Sie dürfen keine Abwesenheiten für andere Mitglieder erstellen."
          );
        } else if (
          this.convertToTimestamp(
            this.leave.duration.start.date,
            this.leave.duration.start.time
          ).valueOf() >
          this.convertToTimestamp(
            this.leave.duration.end.date,
            this.leave.duration.end.time
          ).valueOf()
        ) {
          alert("Fehler: Das Startdatum muss vor dem Enddatum liegen.");
        } else {
          if (!this.permissionToManageLeaves) {
            this.leave = {
              ...this.leave,
              user: {
                displayName: auth.currentUser.displayName,
                membershipId: auth.currentUser.uid,
                uid: auth.currentUser.uid,
              },
            };
          }
          console.log("start");
          this.$store
            .dispatch(`${AVAILABILITY}${CREATE_LEAVE}`, {
              organizationId: this.$route.params.organizationId,
              duration: {
                start: {
                  timestamp: this.convertToTimestamp(
                    this.leave.duration.start.date,
                    this.leave.duration.start.time
                  ),
                },
                end: {
                  timestamp: this.convertToTimestamp(
                    this.leave.duration.end.date,
                    this.leave.duration.end.time
                  ),
                  isOpen: this.leave.duration.end.isOpen,
                },
              },
              reason: {
                id: this.leave.reason.id,
                details: this.leave.reason.details,
              },
              automation: {
                eventResponses: {
                  areAutomated:
                    this.leave.automation.eventResponses.areAutomated,
                  status: this.leave.automation.eventResponses.status,
                  comment: this.leave.automation.eventResponses.comment,
                },
              },
              user: {
                displayName: this.leave.user.displayName,
                membershipId: this.leave.user.membershipId,
                uid: this.leave.user.uid,
              },
            })
            .then(() => {
              return this.$router.push({
                name: "availability-leaves",
                params: {
                  organizationId: this.$route.params.organizationId,
                },
              });
            })
            .catch((error) => {
              this.error.message = error.message;
              console.error(error);
            });
        }
      } else {
        alert("Prüfe Deine Eingabe und versuche es erneut.");
      }
    },
  },
};
</script>
